<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col v-if="fixed" cols="auto" class="align-self-center">
          {{ fixed.name }}
        </v-col>
        <v-col>
          <OrgOrgRoleSelector v-model="role" />
        </v-col>
        <v-col>
          <OrganizationSelector
            v-model="selected"
            :exclude="[this.fixedPk]"
            return-object
          />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto" color="primary">
          <v-btn @click="save()" :disabled="invalid" color="primary">{{
            $t("label.connect")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import OrgOrgRoleSelector from "@/components/blocks/OrgOrgRoleSelector";
import OrganizationSelector from "@/components/blocks/OrganizationSelector";

export default {
  name: "OrgOrgLinkForm",

  components: {
    OrganizationSelector,
    OrgOrgRoleSelector,
  },

  props: {
    linkId: { required: false, type: Number },
    fixed: { required: true, type: Object },
  },

  data() {
    return {
      fixedPk: this.fixed.pk,
      role: null,
      pk: this.linkId,
      selected: null,
    };
  },

  computed: {
    invalid() {
      return !(this.role && this.selected);
    },
  },

  methods: {
    async save() {
      let reverse = this.role.pk < 0;
      let data = {
        primary: reverse ? this.selected.pk : this.fixedPk,
        secondary: reverse ? this.fixedPk : this.selected.pk,
        rel_type: Math.abs(this.role.pk),
      };
      if (this.pk) {
        await axios.patch(`/api/org-org-link/${this.pk}/`, data);
      } else {
        let resp = await axios.post(`/api/org-org-link/`, data);
        this.pk = resp.data.pk;
      }
      this.$emit("save");
    },
    reset() {
      this.role = null;
      this.pk = this.linkId;
      this.fixedPk = this.fixed.pk;
      this.selected = null;
    },
  },
};
</script>

<style scoped></style>
