<template>
  <v-container>
    <v-row>
      <v-col class="pb-0">
        <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card class="pa-md-4 pb-6" v-if="org">
          <v-card-text>
            <section>
              <OrganizationParentsBreadcrumb :org="org" />

              <div class="icons float-right">
                <GhostButton
                  type="edit"
                  v-if="can('change', 'organization')"
                  @click="showEditDialog = true"
                />
                <AddTagButton
                  color="ghost"
                  object-type="organization"
                  :object-id="organizationId"
                  @tag-added="$refs.taglist.fetchTags()"
                  v-if="can('add', 'taggeditem')"
                />
              </div>

              <h2 class="text-h4 pb-4">
                <OrganizationIcon :org="org" large />

                {{ this.org.name }}

                <TagList
                  object-type="organization"
                  :object-id="organizationId"
                  class="ml-4"
                  ref="taglist"
                />
              </h2>

              <table class="overview">
                <tr>
                  <th>{{ $t("label.regNum") }}</th>
                  <td>{{ org.reg_number }}</td>
                </tr>
                <tr>
                  <th>{{ $t("label.address") }}</th>
                  <td><AddressWidget :address="org.address" separate-zip /></td>
                </tr>
                <tr v-if="org.public_notes">
                  <th class="field-name">{{ $t("label.publicNotes") }}</th>
                  <td>{{ org.public_notes }}</td>
                </tr>
                <tr v-if="org.internal_notes">
                  <th class="field-name">{{ $t("label.internalNotes") }}</th>
                  <td>{{ org.internal_notes }}</td>
                </tr>
              </table>
            </section>

            <section class="pt-8">
              <div class="icons float-right">
                <GhostButton
                  type="edit"
                  @click="showContactEditDialog = true"
                  v-if="can('change', 'contact')"
                />
              </div>

              <h3 class="text-h6">{{ $t("label.contacts") }}</h3>
              <ul class="no-bullet">
                <li
                  v-for="contact in org.contacts"
                  :key="`cont-${contact.pk}`"
                  class="py-2"
                >
                  <ContactWidget
                    :contact="contact"
                    show-notes
                    show-suggestions
                  />
                </li>
              </ul>
            </section>

            <section
              class="pt-8"
              v-if="org.organizations && org.organizations.length"
            >
              <h3 class="text-h6">
                <div class="icons float-right">
                  <GhostButton
                    type="add"
                    @click="showOrgOrgLinkDialog = true"
                    v-if="can('add', 'orgorglink')"
                  />
                </div>

                {{ $t("label.relatedOrgs") }}
              </h3>
              <ul class="no-bullet">
                <li
                  v-for="(rel, index) in org.organizations"
                  :key="`org-${index}`"
                  class="py-2"
                >
                  <div class="icons float-right">
                    <GhostButton
                      v-if="can('delete', 'orgorglink')"
                      type="unlink"
                      two-step
                      @click="unlinkOrg(rel)"
                    />
                  </div>
                  <router-link
                    :to="{
                      name: 'organizationDetail',
                      params: { organizationId: rel.pk },
                    }"
                    class="subdued"
                    >{{ rel.name }}</router-link
                  >
                  - <span class="role">{{ rel.role }}</span>
                </li>
              </ul>
            </section>

            <section class="pt-8">
              <h3 class="text-h6">
                <div class="icons float-right">
                  <GhostButton
                    type="add"
                    @click="showPerOrgLinkDialog = true"
                    v-if="can('add', 'perorglink')"
                  />
                </div>
                {{ $t("label.persons") }}
              </h3>
              <ul class="no-bullet">
                <li
                  v-for="perlink in org.person_links"
                  :key="perlink.pk"
                  class="py-2"
                >
                  <div class="icons float-right">
                    <AddTagButton
                      color="ghost"
                      :object-id="perlink.pk"
                      object-type="perorglink"
                      @tag-added="
                        $refs[`perorgtaglist-${perlink.pk}`][0].fetchTags()
                      "
                      v-if="can('add', 'taggeditem')"
                    />
                    <GhostButton
                      type="editLink"
                      @click="updateLink(perlink)"
                      v-if="can('delete', 'perorglink')"
                    />
                    <GhostButton
                      type="unlink"
                      two-step
                      @click="unlink(perlink)"
                      v-if="can('delete', 'perorglink')"
                    />
                  </div>
                  <PersonNameLink :person="perlink.person" />
                  - <span class="role">{{ perlink.relation }}</span>

                  <TagList
                    object-type="perorglink"
                    :object-id="perlink.pk"
                    :ref="`perorgtaglist-${perlink.pk}`"
                  />
                </li>
              </ul>
            </section>
          </v-card-text>
        </v-card>
        <v-sheet v-else class="pt-8">
          <v-skeleton-loader loading type="heading" class="pb-4" />
          <v-skeleton-loader loading type="paragraph" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="showOrgOrgLinkDialog = true"
          v-if="can('add', 'orgorglink')"
          >{{ $t("button.addOrgLink") }}</v-btn
        >
      </v-col>
    </v-row>
    <EditOrganizationDialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      :organization="org"
      @save="
        fetchOrganization();
        showEditDialog = false;
      "
    />
    <EditPerOrgLinkDialog
      v-if="showPerOrgLinkDialog"
      v-model="showPerOrgLinkDialog"
      :organization="org"
      :link-id="editedPerOrgLink ? editedPerOrgLink.pk : null"
      :person="editedPerOrgLink ? editedPerOrgLink.person : null"
      :relation="editedPerOrgLink ? editedPerOrgLink.rel_type : null"
      edited-side="person"
      @save="
        fetchOrganization();
        showPerOrgLinkDialog = false;
        editedPerOrgLink = null;
      "
    />
    <EditContactsDialog
      v-if="org && showContactEditDialog"
      v-model="showContactEditDialog"
      object-type="organization"
      :object-id="organizationId"
      :contacts="org.contacts"
      @save="
        fetchOrganization();
        showContactEditDialog = false;
      "
    />
    <EditOrgOrgLinkDialog
      v-if="org && showOrgOrgLinkDialog"
      v-model="showOrgOrgLinkDialog"
      :fixed="org"
      @save="
        fetchOrganization();
        showOrgOrgLinkDialog = false;
      "
    />
  </v-container>
</template>

<script>
import axios from "axios";
import ContactWidget from "@/components/blocks/ContactWidget";
import PersonNameLink from "@/components/blocks/PersonNameLink";
import GhostButton from "@/components/icons/GhostButton";
import { mapGetters } from "vuex";
import AddTagButton from "@/components/blocks/AddTagButton";
import TagList from "@/components/TagList";
import EditPerOrgLinkDialog from "@/components/dialogs/EditPerOrgLinkDialog";
import EditContactsDialog from "@/components/dialogs/EditContactsDialog";
import OrganizationIcon from "@/components/blocks/OrganizationIcon";
import EditOrganizationDialog from "@/components/dialogs/EditOrganizationDialog";
import AddressWidget from "@/components/blocks/AddressWidget";
import EditOrgOrgLinkDialog from "@/components/dialogs/EditOrgOrgLinkDialog";
import OrganizationParentsBreadcrumb from "@/components/blocks/OrganizationParentsBreadcrumb";

export default {
  name: "OrgDetailPage",

  components: {
    OrganizationParentsBreadcrumb,
    EditOrgOrgLinkDialog,
    AddressWidget,
    EditOrganizationDialog,
    OrganizationIcon,
    EditContactsDialog,
    EditPerOrgLinkDialog,
    AddTagButton,
    PersonNameLink,
    ContactWidget,
    GhostButton,
    TagList,
  },

  props: {
    organizationId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      org: null,
      showEditDialog: false,
      loading: false,
      showPerOrgLinkDialog: false,
      showContactEditDialog: false,
      showOrgOrgLinkDialog: false,
      editedPerOrgLink: null,
    };
  },

  computed: {
    ...mapGetters(["can"]),
    breadcrumbs() {
      return [
        { text: this.$t("label.home"), to: "/" },
        {
          text: this.$t("label.organizations"),
          to: { name: "Home", query: { tab: "organization" } },
        },
        {
          text: this.org ? this.org.name : this.$t("label.organization"),
        },
      ];
    },
  },

  methods: {
    async fetchOrganization() {
      if (this.organizationId) {
        this.loading = true;
        try {
          let resp = await axios.get(
            `/api/organization-detailed/${this.organizationId}/`
          );
          this.org = resp.data;
        } catch (error) {
          console.error("Error fetching organization: ", error);
        } finally {
          this.loading = false;
        }
      }
    },
    async updateLink(perorglink) {
      this.editedPerOrgLink = perorglink;
      this.showPerOrgLinkDialog = true;
    },
    async unlink(perlink) {
      await axios.delete(`/api/perorglink/${perlink.pk}/`);
      await this.fetchOrganization();
    },
    async unlinkOrg(link) {
      await axios.delete(`/api/org-org-link/${link.link_pk}/`);
      await this.fetchOrganization();
    },
  },

  watch: {
    organizationId: {
      immediate: true,
      handler() {
        this.fetchOrganization();
      },
    },
  },
};
</script>
